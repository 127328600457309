import React from 'react';
import VerticalTabNav, { MenuItem } from '../../../components/navigation/VerticalTabNav';

const menuItems: MenuItem[] = [
    {
        label: 'Contrato',
        path: '/contract_states',
        children: [
            {
                label: 'Producto del contrato',
                path: '/contract_product_states',
                permission: {
                    group: 'user',
                    action: 'get_user',
                },
            },
            {
                label: 'Facturación del contrato',
                path: '/contract_invoicing_states',
                permission: {
                    group: 'user',
                    action: 'get_user',
                },
            },
            {
                label: 'Incidencia del contrato',
                path: '/contract_incidence_states',
                permission: {
                    group: 'user',
                    action: 'get_user',
                },
            }
        ],
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
    {
        label: 'Vencimiento',
        path: '/expiration_states',
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
    {
        label: 'Facturas',
        path: '/invoice_states',
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
    {
        label: 'Incidencia',
        path: '/incidence_states',
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
    {
        label: 'Renovación',
        path: '/renovation_states',
        permission: {
            group: 'user',
            action: 'get_user',
        },
    },
];

export default function StatesListLayout({ children }: { children: React.ReactNode }) {
    return (
        <div className="grid grid-cols-1 lg:grid-cols-5 gap-4">
            <div className="lg:col-span-1 col-span-5">
                <VerticalTabNav menuItems={menuItems} />
            </div>
            <div className="lg:col-span-4 col-span-5">
                {children}
            </div>
        </div>
    );
};