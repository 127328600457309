import { Link, useLocation } from "react-router-dom";
import cn from "../../../utils/classNames";
import HamburgerButton from "../settings/hamburger-button";
import logo from "../../../assets/attiva_logo.png";
import fullLogo from "../../../assets/logo_completo.png";
import ProfileMenu from "../settings/profile-menu";
import Sidebar from "../_sidebar/sidebar";
import { useIsMounted } from "../../../hooks/use-is-mounted";
import { useWindowScroll } from "react-use";
import HeaderMenuLeft from "./header-menu-left";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useEffect, useState } from "react";


type StickyHeaderProps = {
  className?: string;
  offset?: number;
};

function StickyHeader({
  offset = 2,
  className,
  children,
}: React.PropsWithChildren<StickyHeaderProps>) {
  const isMounted = useIsMounted();
  const windowScroll = useWindowScroll();
  return (
    <header
      className={cn(
        "sticky top-0 z-[9999] flex items-center bg-gray-0/80 p-4 backdrop-blur-xl dark:bg-gray-50/50 md:px-5 lg:px-6",
        ((isMounted && windowScroll.y) as number) > offset ? "card-shadow" : "",
        className
      )}
    >
      {children}
    </header>
  );
}

function HeaderMenuRight() {
  return (
    <div className="ms-auto flex shrink-0 items-center gap-2 text-gray-700 xs:gap-3 xl:gap-4">
      {/* <MessagesDropdown>
        <ActionIcon
          aria-label="Messages"
          variant="text"
          className={cn(
            ' relative h-[34px] w-[34px] overflow-hidden rounded-full md:h-9 md:w-9 3xl:h-10 3xl:w-10 '
          )}
        >
          <PiChatsCircleDuotone className="h-6 w-auto" />
          <Badge
            renderAsDot
            color="success"
            enableOutlineRing
            className="absolute right-1 top-2.5 -translate-x-1 -translate-y-1/4"
          />
        </ActionIcon>
      </MessagesDropdown>

      <NotificationDropdown>
        <ActionIcon
          aria-label="Notification"
          variant="text"
          className={cn(
            'relative h-[34px] w-[34px] overflow-hidden rounded-full md:h-9 md:w-9 3xl:h-10 3xl:w-10'
          )}
        >
          <PiBellSimpleRingingDuotone className="h-6 w-auto" />
          <Badge
            renderAsDot
            color="warning"
            enableOutlineRing
            className="absolute right-1 top-2.5 -translate-x-1 -translate-y-1/4"
          />
        </ActionIcon>
      </NotificationDropdown>

      <PiGearDuotone className="h-[22px] w-auto animate-spin-slow" /> */}

      <ProfileMenu
        buttonClassName="w-auto sm:w-auto p-1"
        avatarClassName="!w-7 !h-7 sm:!h-8 sm:!w-8"
      />
    </div>
  );
}

export default function Header() {

    const [lastUserId , setLastUserId] = useState("");
    const user = useSelector((state: RootState) => state.auth.user);
    const location = useLocation();

    useEffect(() => {
        if ( user && user?.id !== lastUserId ) {
            setLastUserId(user.id);
        }

        return () => {
            setLastUserId("");
        }
    }, [user])
    

  return (
    <StickyHeader
      className={"z-[990] justify-between 2xl:py-5 2xl:pl-6  3xl:px-8"}
    >
      <div className="hidden items-center gap-3 xl:flex">
        <Link
          aria-label="Site Logo"
          to={"/"}
          className="me-4 hidden w-[155px] shrink-0 text-gray-800 hover:text-gray-900 lg:me-5 xl:block"
        >
          <img src={fullLogo} width={"130px"} alt="Logo de Attiva" />
        </Link>
        <HeaderMenuLeft pathname={location.pathname} key={lastUserId} />
      </div>
      <div className="flex w-full items-center gap-5 xl:w-auto 3xl:gap-6">
        <div className="flex w-full max-w-2xl items-center xl:w-auto">
          <HamburgerButton
            view={<Sidebar className="static w-full 2xl:w-full" />}
          />
          <Link
            aria-label="Site Logo"
            to={"/"}
            className="me-4 w-9 shrink-0 text-gray-800 hover:text-gray-900 lg:me-5 xl:hidden"
          >
            <img src={logo} width={"80px"} alt="Logo de Attiva" />
          </Link>
        </div>
        <HeaderMenuRight />
      </div>
    </StickyHeader>
  );
}
