import React, { useCallback, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineHistoryEdu, MdOutlineLockReset, MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineEye } from "react-icons/hi2";
import DeletePopover from '../../../components/buttons/DeletePopover';
import moment from 'moment';
import { menuRoutes } from '../../../router/menu';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomPopover from '../../../components/buttons/Popover';
import CorporationsFilters from './StatesFilters';
import { CorporationService } from '../../../services/corporation/corporationService';
import { CorporationsApiResponse } from '../../../type/entities/corporation-type';
import { StateService } from '../../../services/state/stateService';
import StatesListLayout from './StatesListLayout';

interface StatesListProps {
}

const StatesList: React.FC<StatesListProps> = () => {

    const { user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new StateService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listStates(filters);
        return response.getResponseData() as CorporationsApiResponse;
    }, [filters]));

    const toggleState = async (id: string, state: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleStateStatus(id, state)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(state ? "Se ha desactivado la empresa" : "Se ha activado la empresa");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        await service.deleteState(id);
        refetch();
    };

    const handleMultiDelete = async (ids: string[]) => {
        await service.deleteMultiStates(ids);
        refetch();
    };

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <StatesListLayout>
                    <div className="@container">
                        <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">

                        </div>
                    </div>
                </StatesListLayout>
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Usuarios'}>
                <div className="grid grid-cols-1 gap-6">
                    <CorporationsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page>
    );
};

export default StatesList;