import {createColumnHelper} from '@tanstack/react-table';
import {Checkbox, Text} from 'rizzui';
import AvatarCard from '../../ui/AvatarCard';
import {useContext, useEffect, useState} from 'react';
import {PrivilegeContext} from '../../priviledge/PriviledgeProvider';
import {imageFields, translateColumnName} from '../../../utils/tableUtils';
import {Link} from 'react-router-dom';
import AsyncImg from '../../extras/AsyncImg';

const columnHelper = createColumnHelper();

const DynamicTableColumns = (
    {
        id,
        data,
        columnsNotShown,
        overrideColumns = [],
        additionalColumns = [],
        actions = [],
        columnOrder
    }: any) => {

    const {userCan} = useContext(PrivilegeContext);

    const [configuredColumns, setConfiguredColumns] = useState<any[]|null>(null);

    const configureDynamicColumns = () => {

        if (data === undefined || !data || data.length === 0){
            setConfiguredColumns([])
            return;
        }

        const firstRow = data[0];
        const columns = [];

        /**
         * Añadir columna de selección de filas
         */
        columns.push(columnHelper.accessor('id', {
            id: 'id',
            size: 60,
            header: ({table}) => (
                <Checkbox
                    aria-label="Selecciona todas las filas"
                    checked={table.getIsAllPageRowsSelected()}
                    onChange={() => table.toggleAllPageRowsSelected()}
                />
            ),
            cell: ({row}) => (
                <Checkbox
                    aria-label="Selecciona esta fila"
                    checked={row.getIsSelected()}
                    onChange={() => row.toggleSelected()}
                />
            ),
            enableSorting: false,
            meta: {isHidden: true},
        }));

        /**
         * Si columnsNotShown contiene la columna id, se elimina de la lista de columnas
         * Se realiza un pop() al ser la única columna que se puede eliminar
         */
        if (columnsNotShown && columnsNotShown.includes('id')) {
            columns.pop();
        }

        /**
         * Añadir columnas de datos de la tabla
         */
        Object.keys(firstRow).forEach((key) => {
            if (key === 'id' || key === 'updatedAt') return;
            if (key === 'name' && imageFields.some((field) => key)) return;
            if (columnsNotShown && columnsNotShown.includes(key)) return;

            // Buscar override para la columna
            const overrideColumn = overrideColumns.find((col: any) => col.key === key);

            const columnConfig = {
                id: key,
                size: key.length * 20,
                header: translateColumnName(key),
                cell: ({row}: any) => {
                    const value = row.original[key];

                    if (typeof value === 'object' && value !== null) {
                        if (value.date) {
                            return new Date(value.date).toLocaleDateString();
                        }
                        if (value) {
                            return (
                                <Link to={`/users/${row.original.id}/edit`}>
                                    <div className="flex items-center gap-3">
                                        <AsyncImg height="50px" width="50px" className="rounded" id={value.id}/>
                                        <Text>{row.original.name + (row.original.lastName ? ` ${row.original.lastName}` : '')}</Text>
                                    </div>
                                </Link>
                            )
                        }
                        return JSON.stringify(value);
                    }

                    if (imageFields.includes(key)) {
                        return <AvatarCard src={'default-avatar.png'}
                                           name={row.original.name + (row.original.lastName ? ` ${row.original.lastName}` : '')}/>;
                    }

                    if (key === 'name' && row.original.lastName) {
                        return `${value} ${row.original.lastName}`;
                    }

                    if (typeof value === 'string' && value.length > 50) {
                        return `${value.substring(0, 50)}...`;
                    }

                    return value;
                },
                enableSorting: true,
            };

            // Si hay un override para esta columna, se sobreescribe la función de renderizado
            if (overrideColumn) {
                columnConfig.cell = overrideColumn.render;
            }

            columns.push(columnHelper.accessor(key, columnConfig));
        });

        /**
         * Añadir columnas adicionales
         */
        additionalColumns.forEach((col: any) => {
            columns.push(columnHelper.accessor(col.id, {
                id: col.id,
                size: col.size || 100,
                header: col.header || translateColumnName(col.id),
                cell: col.render,
                enableSorting: col.enableSorting || false,
            }));
        });

        /**
         * Añadir columna de acciones
         */
        if (actions.length > 0) {
            columns.push(columnHelper.accessor('actions', {
                id: 'actions',
                size: actions.length * 2,
                header: () => <div style={{textAlign: 'center'}}>Acciones</div>,
                cell: ({row}: any) => (
                    <div className="flex items-center justify-center gap-3 pe-3">
                        {actions.map((action: any, index: number) => {
                            if (action.permissions && !userCan(action.permissions.permission, action.permissions.group)) return;
                            return (
                                <div key={index} className="flex items-center gap-2">
                                    {action.render && action.render(row.original)}
                                </div>
                            )
                        })}
                    </div>
                ),
                enableSorting: false,
            }));
        }

        /**
         * Ordenar columnas según el orden indicado
         * Si no se indica un orden, se mantiene el orden original
         */
        if (columnOrder) {

            const sortedColumns = columns.sort((a, b) => {
                // Make id column be first always
                if (a.id === 'id') return -1;
                if (b.id === 'id') return 1;

                // Make actions columns always de last
                if (a.id === 'actions') return 1;
                if (b.id === 'actions') return -1;

                const indexA = columnOrder.indexOf(a.id);
                const indexB = columnOrder.indexOf(b.id);

                if (indexA !== -1 && indexB !== -1) {
                    return indexA - indexB;
                }

                if (indexA !== -1) return -1;
                if (indexB !== -1) return 1;

                return 0;
            });

            setConfiguredColumns(sortedColumns);
        }

        setConfiguredColumns(columns);
    }


    useEffect(() => {
        if(Array.isArray(data)) {
            configureDynamicColumns();
        }
    }, [data]);

    return {
        configuredColumns
    }

};

export default DynamicTableColumns;