import React, { useEffect, useState } from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button, Input } from 'rizzui';
import { FilterOptions } from '../../../../../hooks/useFilters';
import DateFiled from '../../../../../components/forms/DateField';
import moment from 'moment';

interface UserChangelogsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const UserChangelogsFilters: React.FC<UserChangelogsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const [filter_filters, setFilterFilters] = useState<any>({});
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (loaded && filter_filters !== filters.filter_filters) {
            const delaySearch = setTimeout(() => {
                updateFilters(filter_filters);
            }, 1000);
            return () => clearTimeout(delaySearch);
        }
    }, [filter_filters, loaded]);

    useEffect(() => {
        setFilterFilters(filters.filter_filters || '');
    }, [filters]);

    useEffect(() => {
        setLoaded(true);
    }, []);


    return (
        <>
            <Input
                id='search_text'
                type="text"
                label="Buscar"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={(e) => setFilterFilters({ ...filter_filters, search_text: e.target.value })}
                value={filter_filters?.search_text ?? ''}
            />

            <DateFiled
                className="w-full"
                placeholderText="Selececiona las fechas"
                startDate={filter_filters?.between_dates?.startDate ? new Date(filter_filters.between_dates.startDate) : null}
                endDate={filter_filters?.between_dates?.endDate ? new Date(filter_filters.between_dates.endDate) : null}
                onChange={(date: any) => {
                    setFilterFilters({ 
                            ...filter_filters, 
                            between_dates: {
                                startDate: date[0] ? moment(date[0]).format('YYYY-MM-DD') : null, 
                                endDate: date[1] ? moment(date[1]).format('YYYY-MM-DD') : null
                            } 
                        }
                    )
                }}
            />


            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default UserChangelogsFilters;