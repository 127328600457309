import { Fragment, useContext } from "react";
import { FiltersProvider } from "../../../components/providers/FiltersProvider";
import PageHeader from "../../../layout/shared/page-header";
import { Button } from "rizzui";
import { PrivilegeContext } from "../../../components/priviledge/PriviledgeProvider";
import { useNavigate } from "react-router";
import { menuRoutes } from "../../../router/menu";
import cn from "../../../utils/classNames";
import CorporationsList from "./CorporationsList";

const CorporationsListWrapper = () => {

    const { userCan } = useContext(PrivilegeContext);
    const navigate = useNavigate();

    const breadCrumb = [
        { name: 'Administración' },
        { name: 'Empresas', href: menuRoutes.corporations.path },
    ];

    return (
        <Fragment>
            <FiltersProvider>
                <PageHeader title="Listado de empresas" breadcrumb={breadCrumb}>
                    {userCan('create_corporation', 'corporation') && (
                        <Button
                            color="primary"
                            onClick={() => { navigate(menuRoutes.corporations.create) }}
                            className={cn(['mt-4 md:mt-0'])}
                        >
                            Crear empresa
                        </Button>
                    )}
                </PageHeader>
                <CorporationsList />
            </FiltersProvider>
        </Fragment>
    );
}

export default CorporationsListWrapper;