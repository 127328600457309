import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const CORPORATION_ENDPOINT = '/corporation';

export class CorporationService extends RestServiceConnection {

    listCorporations = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getCorporationById = async (corporationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/get',
            data: { corporationId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleCorporationStatus = async (corporationId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/toggle',
            data: { corporationId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createCorporation = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/create',
            data: user,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    editCorporation = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/edit',
            data: user,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    deleteCorporation = async (corporationId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/delete',
            data: { corporationId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiCorporations = async (usersIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: CORPORATION_ENDPOINT + '/delete-multi',
            data: { usersIds }
        }, true);
        return this;
    }

}