import { HeaderMenuItem } from "../type/menu-type";

export const menuRoutes = {
    dashboard: {
        path: "/dashboard",
    },
    organisations: {
        path: "/organisations",
        create: "create",
        edit: ":id/edit",
    },
    roles: {
        path: "/roles",
        create: "create",
        edit: ":id/edit",
    },
    users: {
        path: "/users",
        create: "create",
        edit: ":id/edit/info",
        edit_permissions: ":id/edit/permissions",
        profile: {
            path: ":id/profile",
            info: "/info",
            stats: "/stats",
            documents: "/documents",
            permissions: "/permissions",
            contract_history: "/contract-history",
            activity: "/activity",
        },
    },
    states: {
        path: "/states",
        create: "create",
        edit: ":id/edit",
    },
    corporations: {
        path: "/corporations",
        create: "create",
        edit: ":id/edit",
    },
};

export const attivaMenuItems: HeaderMenuItem = {
    dashboardMenu: {
        id: "dashboard",
        name: "Inicio",
        path: "/dashboard",
        type: "enhance",
        permissions_required: [{
            group: "dashboard",
            action: "get_dashboard_information",
        }],
    },
    administrationMenu: {
        id: "administration",
        name: "Administración",
        path: "/admin",
        react_icon: "PiFolder",
        type: "enhance",
        dropdownItems: [
            {
                id: "access-and-credentials",
                name: "Control de acceso",
                path: "",
                icon: "UserLockIcon",
                subMenuItems: [
                    {
                        name: "Usuarios",
                        href: menuRoutes.users.path,
                        icon: "UserListIcon",
                        permissions_required: [
                            {
                                group: "user",
                                action: "list_user",
                            }
                        ],
                    },
                    {
                        name: "Roles",
                        href: menuRoutes.roles.path,
                        icon: "RolesIcon",
                        permissions_required: [
                            {
                                group: "roles",
                                action: "list_roles",
                            },
                        ],
                    },
                ]
            },

        ]
    },
    /*
    usersMenu: {
        id: "users",
        name: "Usuarios",
        path: menuRoutes.users.path,
        react_icon: "PiFolder",
        type: "enhance",
        permissions_required: [
            {
                group: "user",
                action: "admin_user",
            },
        ],
        dropdownItems: [
            {
                id: "users",
                name: "Listado de usuarios",
                path: menuRoutes.users.path,
                icon: "DocumentIcon",
                react_icon: "PiUserCircleDuotone",
                permissions_required: [
                    {
                        group: "user",
                        action: "list_user",
                    },
                ]
            },
            {
                id: "users-create",
                name: "Crear Usuario",
                path: menuRoutes.users.path+'/'+menuRoutes.users.create,
                icon: "UserPlusIcon",
                react_icon: "PiUserPlusDuotone",
                permissions_required: [
                    {
                        group: "user",
                        action: "create_user",
                    },
                ],
            },
        ],
    },

    rolesMenu: {
        id: "roles",
        name: "Roles",
        path: menuRoutes.roles.path,
        react_icon: "PiFolder",
        type: "link",
        permissions_required: [
            {
                group: "roles",
                action: "admin_roles",
            },
        ],
        dropdownItems: [
            {
                id: "roles",
                name: "Listado de roles",
                path: menuRoutes.roles.path,
                icon: "DocumentIcon",
                react_icon: "PiUserCircleDuotone",
                permissions_required: [
                    {
                        group: "roles",
                        action: "list_roles",
                    },
                ],
            },
            {
                id: "roles-create",
                name: "Crear Role",
                path: menuRoutes.roles.path+'/'+menuRoutes.roles.create,
                icon: "AddIcon",
                react_icon: "PiUserPlusDuotone",
                permissions_required: [
                    {
                        group: "roles",
                        action: "create_roles",
                    },
                ],
            },
        ],
    },
    */
    superAdminMenu: {
        id: "title-super-admin",
        name: "Panel Administración",
        icon: "GreenLeafIcon",
        react_icon: "PiFolder",
        type: "enhance",
        columns: "1",
        permissions_required: [
            {
                group: "companies",
                action: "admin_company",
                isSuperAdmin: true,
            },
        ],
        dropdownItems: [
            {
                id: "organisations",
                name: "Organizaciones",
                path: "/organisations",
                icon: "ApartmentIcon",
                react_icon: "PiGridFourDuotone",
                permissions_required: [
                    {
                        group: "companies",
                        action: "admin_company",
                        isSuperAdmin: true,
                    },
                ],
                subMenuItems: [
                    {
                        name: "Lista Organizaciones",
                        href: menuRoutes.organisations.path,
                        permissions_required: [
                            {
                                group: "companies",
                                action: "list_company",
                            }
                        ],
                    },
                    {
                        name: "Crear Organización",
                        href: menuRoutes.organisations.path + '/' + menuRoutes.organisations.create,
                        permissions_required: [
                            {
                                group: "companies",
                                action: "create_company",
                            }
                        ],
                    },
                ]
            },
            {
                id: "roles",
                name: "Roles",
                path: "/roles",
                icon: "RolesIcon",
                react_icon: "PiNewspaperClippingDuotone",
                permissions_required: [
                    {
                        group: "roles",
                        action: "admin_roles",
                        isSuperAdmin: true,
                    },
                ],
                subMenuItems: [
                    {
                        name: "Lista Roles",
                        href: menuRoutes.roles.path,
                        permissions_required: [
                            {
                                group: "roles",
                                action: "list_roles",
                            }
                        ],
                    },
                    {
                        name: "Crear Rol",
                        href: menuRoutes.roles.create,
                        permissions_required: [
                            {
                                group: "roles",
                                action: "create_roles",
                            }
                        ],
                    },
                ]
            },
        ],
    },
};