import { AxiosResponse } from 'axios';
import { RestServiceConnection } from '../restServiceConnection';

const STATE_ENDPOINT = '/statuses';

export class StateService extends RestServiceConnection {

    listStates = async (filters?: any) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/list',
            data: filters,
        }, true) as AxiosResponse;
        return this;
    }

    getStateById = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/get',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    toggleStateStatus = async (statusId: string, status: boolean) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/toggle',
            data: { statusId, active: status },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    createState = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/create',
            data: user,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }, true);
        return this;
    }

    editState = async (user: FormData) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/edit',
            data: user,
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
        }, true);
        return this;
    }

    deleteState = async (statusId: string) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/delete',
            data: { statusId },
            headers: { "Content-Type": "application/json" }
        }, true);
        return this;
    }

    deleteMultiStates = async (usersIds: string[]) => {
        this.response = await this.makeRequest({
            method: 'POST',
            url: STATE_ENDPOINT + '/delete-multi',
            data: { usersIds }
        }, true);
        return this;
    }

}