import classNames from 'classnames';
import React from 'react';
import SelectReact from 'react-select';

type SelectProps = {
    id: string;
    label: string;
    error?: string;
    isSearchable?: boolean;
    isMulti?: boolean;
    isClearable?: boolean;
    isDisabled?: boolean;
    options?: any;
    onChange?: any;
    handle?: any;
    value?: any;
    defaultValue?: any;
    placeholder?: string;
    noOption?: any;
    className?: string;
    onBlur?: any;
    display?: boolean;
    required?: boolean;
};

const CustomSelect: React.FC<SelectProps> = ({ label, id, isSearchable, isMulti, isClearable, isDisabled, required, options, onChange, handle, value, defaultValue, placeholder, noOption, className, onBlur, display = true, error }) => {
    return (
        <div className={classNames({ 'hidden': !display })}>
            <label className='block'>
                <span className="rizzui-input-label block text-sm mb-1.5 font-medium">{label} {required && <span className="text-red-500">*</span>}</span>

                <SelectReact
                    id={id}
                    name={id}
                    tabSelectsValue
                    isSearchable={isSearchable}
                    isMulti={isMulti}
                    isClearable={isClearable}
                    isDisabled={isDisabled}
                    options={options}
                    onChange={onChange ? ((selectedOption: any) => { onChange(selectedOption) }) : handle}
                    value={value}
                    defaultValue={defaultValue}
                    onBlur={onBlur}
                    placeholder={`Elegir ${placeholder || ''}...`}
                    noOptionsMessage={noOption || (() => 'No se ha encontrado ningún resultado')}
                    className={className}
                    styles={{
                        control: (base: any, state: any) => ({
                            ...base,
                            border: '1px !important',
                            borderRadius: '0.375rem',
                            borderColor: 'rgb(229 231 235) !important',
                            boxShadow: state.isFocused ? '0 0 0 2px rgb(0, 172, 216)' : '0 0 0 1px rgba(0,0,0, 0.2) !important',
                            backgroundColor: isDisabled ? '#e9ecef' : 'transparent',
                        }),
                        option: (provided: any, state: any) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'rgb(0, 172, 216)' : 'white',
                            color: state.isFocused ? 'white' : 'black',
                            '&:hover': {
                                backgroundColor: 'rgb(0, 172, 216)',
                                color: 'white',
                                borderColor: '#000000 !important'
                            }
                        }),
                    }}
                />
            </label>

            {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
        </div>
    );
};

export default CustomSelect;