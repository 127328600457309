import { Navigate, Route, Routes } from 'react-router-dom';
import contents from './routes';
import ProtectedRoute from './ProtectedRoute';
import AppLayout from '../pages/_layout/AppLayout';
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Login from '../pages/auth/Login';
import Page404 from '../pages/auth/Page404';
import ForgotPassword from '../pages/auth/ForgotPassword';
import ResetPassword from '../pages/auth/ResetPassword';

const AppRouter = () => {

  const { isAuthenticated } = useSelector((state: RootState) => state.auth);

  const renderRoute = (page: any, index: number) => {
    page.element = <ProtectedRoute component={page.element} access={page.access} />;

    return (
      <Route key={index} {...page}>
        {page.sub?.map((subPage: any, index: number) => renderRoute(subPage, index))}
      </Route>
    );
  };

  return (
    <Routes>
      <Route element={<AppLayout />}>
        {contents.map((page, index) => renderRoute(page, index))}
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {!isAuthenticated && <Route path="*" element={<Navigate to={"/login"} />} />}
      {isAuthenticated && <Route path="/" element={<Navigate to={"/dashboard"} />} />}
      {isAuthenticated && <Route path="*" element={<Page404 />} />}
    </Routes>
  );
};

export default AppRouter;