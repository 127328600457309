import React, {FC, useCallback, useEffect, useState} from "react";
import UserEditLayout from "./UserEditLayout";
import {useParams} from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import {User, UserApiResponse} from "../../../type/entities/user-type";
import {UserService} from "../../../services/user/userService";
import {PermissionGroup, PermissionsApiResponse, RolePermission} from "../../../type/entities/role-type";
import FormGroup from "../../../layout/shared/form-group";
import {
    PermissionsGrid,
} from "../../../components/ui/PermissionsAccordion";
import {PermissionService} from "../../../services/auth/permissionService";
import {useFormik} from "formik";
import FormFooter from "../../_layout/_footers/form-footer";
import {toast} from "sonner";
import {menuRoutes} from "../../../router/menu";
import {useNavigate} from "react-router";


const UserEditPermissions: FC = () => {

    const {id = ""} = useParams<{ id: string }>();
    const service = new UserService();
    const navigate = useNavigate();

    const [selectedPermissions, setSelectedPermissions] = useState<number[] | null>([]);
    const [updating, setUpdating] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {},
        onSubmit: values => {
            updatePermissions();
        },
    });


    // GET User Selected permissions
    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await service.getUserById(id as string);
        return response.getResponseData() as User;
    }, [id]));


    // GET Avaliable Permissions
    const [permissionsData, permissionsLoading, permissionsError] = useFetch(useCallback(async () => {
        const response = await (new PermissionService()).getPermissions();
        return response.getResponseData() as PermissionsApiResponse;
    }, []));

    const _handleSelectedPermissions = (permissions: RolePermission[]) => {
        let permissionsIds = permissions.map((permission: RolePermission) => permission.permission.id);

        setSelectedPermissions(permissionsIds);
    }

    const _handleSelectAll = (permissions: number[], checked: boolean) => {


        if(checked && selectedPermissions !== null) {
            // if already in selectedPermissions, do nothing
            let permissionsToAdd: number[] = [];
            permissions.forEach((permission: number) => {
                if(!selectedPermissions.includes(permission)) {
                    permissionsToAdd.push(permission);
                }
            });
            setSelectedPermissions([...selectedPermissions, ...permissionsToAdd]);
        }


        if(!checked && selectedPermissions !== null) {

            const selectedPermissionsCopy = selectedPermissions;

            permissions.forEach((permission: number) => {
                const index = selectedPermissionsCopy.indexOf(permission);
                if(index > -1) {
                    selectedPermissionsCopy.splice(index, 1);
                }
            });

            setSelectedPermissions([...selectedPermissionsCopy]);

        }
    }


    const updatePermissions = async () => {
        try {
            setUpdating(true);

            if(selectedPermissions) {
                let response = await (await service.editUserPermissions(id, data.userRoles[0].company?.id, selectedPermissions)).getResponseData() as UserApiResponse;
                setUpdating(false);
                if (response.success && response.data) {
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
            }

        } catch (error: any) {
            toast.error('Error al actualizar los permisos');
        } finally {
            setUpdating(false);
        }
    };

    const _handleOnSelectPermission = (permission: number, checked: boolean) => {

        if(checked && selectedPermissions !== null) {
            if(!selectedPermissions.includes(permission)) {
                setSelectedPermissions([...selectedPermissions, permission]);
            }
        }

        if(!checked && selectedPermissions !== null) {
            const selectedPermissionsCopy = selectedPermissions;
            const index = selectedPermissionsCopy.indexOf(permission);
            if(index > -1) {
                selectedPermissionsCopy.splice(index, 1);
            }
            setSelectedPermissions([...selectedPermissionsCopy]);
        }
    }

    useEffect(() => {
        if (data) _handleSelectedPermissions(data.userPermissions);
    }, [data]);

    return (
        <UserEditLayout>
            <div className="@container">
                <form onSubmit={formik.handleSubmit} autoComplete="off" className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                    {selectedPermissions !== null && permissionsData &&
                        permissionsData.map((permissionGroup: PermissionGroup, index: number) => {

                            return (
                                <FormGroup
                                    key={`index-${permissionGroup.id}`}
                                    title={permissionGroup.label}
                                    description={'Lista de permisos asignados al usuario'}
                                    className='pt-6'
                                    childClassName='col-span-full grid gap-4 sm:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 @4xl:col-span-8 @4xl:gap-5 xl:gap-7'
                                >
                                    <div key={permissionGroup.id} className={"col-span-full"}>
                                        <PermissionsGrid
                                            group={permissionGroup}
                                            onSelectAll={_handleSelectAll}
                                            onSelectPermission={_handleOnSelectPermission}
                                            selectedPermissions={selectedPermissions}
                                        />
                                    </div>
                                </FormGroup>
                            )
                        })
                    }
                    <FormFooter
                        submitBtnText={'Guardar Permisos'}
                        handleSubmitBtn={formik.submitForm}
                        handleCancelBtn={() => navigate(menuRoutes.users.path)}
                        isLoading={updating}
                    />
                </form>
            </div>
        </UserEditLayout>
    )
}

export default UserEditPermissions;