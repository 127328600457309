import React, { useCallback } from 'react';
import ProfileLayout from '../ProfileLayout';
import { useParams } from 'react-router-dom';
import { UserService } from '../../../../services/user/userService';
import useFetch from '../../../../hooks/useFetch';
import { UserApiResponse } from '../../../../type/entities/user-type';
import { Input } from 'rizzui';
import { PiEnvelopeSimple } from 'react-icons/pi';
import FormGroup from '../../../../layout/shared/form-group';
import AsyncImg from '../../../../components/extras/AsyncImg';
import PlaceholderImage from '../../../../components/extras/PlaceholderImage';
import cn from '../../../../utils/classNames';
import CustomSelect from '../../../../components/forms/CustomSelect';
import useRoles from '../../../../hooks/api-calls/useRoles';

interface UserInfoProps { }

const UserInfo: React.FC<UserInfoProps> = () => {

    const { id = '' } = useParams<{ id: string }>();
    const { getRolesList } = useRoles();
    const service = new UserService();

    const [data] = useFetch(useCallback(async () => {
        if (!id || id === '') return null;
        const response = await service.getUserById(id);
        return response.getResponseData() as UserApiResponse;
    }, [id]));

    function getUserRolesValues() {
        return data?.userRoles.map((roleWrapper: any) => (
            { value: roleWrapper.role.id, label: roleWrapper.role.name }
        ));
    };

    return (
        <ProfileLayout>
            <div className="@container">
                <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11">
                    <FormGroup
                        title="Imagen de perfil"
                        description="Se mostrará como imagen de perfil del usuario"
                        className={cn(['pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11 ', data?.profileImg?.id ? '' : 'hidden'])}
                    >
                        {data?.profileImg?.id
                            ? <AsyncImg id={data?.profileImg?.id} isBackground className="mx-auto d-block img-fluid mb-3 rounded" />
                            : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid mb-3 rounded' />
                        }
                    </FormGroup>

                    <FormGroup
                        title="Datos Personales"
                        description='Información personal del usuario'
                        className="pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <Input
                            disabled
                            label="Nombre"
                            placeholder="First Name"
                            value={data?.name ?? ''}
                            className="flex-grow"
                        />
                        <Input
                            disabled
                            label="Apellidos"
                            placeholder="Last Name"
                            value={data?.lastName ?? ''}
                            className="flex-grow"
                        />

                        <Input
                            className="col-span-full"
                            prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                            type="email"
                            label="Email"
                            placeholder="georgia.young@attiva.com"
                            value={data?.email ?? ''}
                            disabled
                        />
                    </FormGroup>

                    <FormGroup
                        title="Rol del usuario"
                        className="pt-7 @2xl:pt-9 @3xl:grid-cols-12 @3xl:pt-11"
                    >
                        <CustomSelect
                            isDisabled
                            id={'roleId'}
                            label="Rol"
                            onChange={() => { }}
                            value={getUserRolesValues()}
                            options={getRolesList()}
                        />
                    </FormGroup>
                </div>
            </div>
        </ProfileLayout>
    );
};

export default UserInfo;