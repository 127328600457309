import {
    PiShoppingCartDuotone,
    PiHeadsetDuotone,
    PiPackageDuotone,
    PiChartBarDuotone,
    PiCurrencyDollarDuotone,
    PiSquaresFourDuotone,
    PiGridFourDuotone,
    PiFeatherDuotone,
    PiChartLineUpDuotone,
    PiMapPinLineDuotone,
    PiUserGearDuotone,
    PiBellSimpleRingingDuotone,
    PiUserDuotone,
    PiEnvelopeSimpleOpenDuotone,
    PiStepsDuotone,
    PiCreditCardDuotone,
    PiTableDuotone,
    PiBrowserDuotone,
    PiHourglassSimpleDuotone,
    PiUserCircleDuotone,
    PiShootingStarDuotone,
    PiRocketLaunchDuotone,
    PiFolderLockDuotone,
    PiBinocularsDuotone,
    PiHammerDuotone,
    PiNoteBlankDuotone,
    PiUserPlusDuotone,
    PiShieldCheckDuotone,
    PiLockKeyDuotone,
    PiChatCenteredDotsDuotone,
    PiCalendarPlusDuotone,
    PiEnvelopeDuotone,
    PiCurrencyCircleDollarDuotone,
    PiBriefcaseDuotone,
    PiHouseLineDuotone,
    PiAirplaneTiltDuotone,
    PiFolder,
    PiCaretCircleUpDownDuotone,
    PiListNumbersDuotone,
    PiCoinDuotone,
    PiCalendarDuotone,
    PiShapesDuotone,
    PiNewspaperClippingDuotone,
} from 'react-icons/pi';

export const reactMenuIcons = {
    PiShoppingCartDuotone,
    PiHeadsetDuotone,
    PiPackageDuotone,
    PiChartBarDuotone,
    PiCurrencyDollarDuotone,
    PiSquaresFourDuotone,
    PiGridFourDuotone,
    PiFeatherDuotone,
    PiChartLineUpDuotone,
    PiMapPinLineDuotone,
    PiUserGearDuotone,
    PiBellSimpleRingingDuotone,
    PiUserDuotone,
    PiEnvelopeSimpleOpenDuotone,
    PiStepsDuotone,
    PiCreditCardDuotone,
    PiTableDuotone,
    PiBrowserDuotone,
    PiHourglassSimpleDuotone,
    PiUserCircleDuotone,
    PiShootingStarDuotone,
    PiRocketLaunchDuotone,
    PiFolderLockDuotone,
    PiBinocularsDuotone,
    PiHammerDuotone,
    PiNoteBlankDuotone,
    PiUserPlusDuotone,
    PiShieldCheckDuotone,
    PiLockKeyDuotone,
    PiChatCenteredDotsDuotone,
    PiCalendarPlusDuotone,
    PiEnvelopeDuotone,
    PiCurrencyCircleDollarDuotone,
    PiBriefcaseDuotone,
    PiHouseLineDuotone,
    PiAirplaneTiltDuotone,
    PiFolder,
    PiCaretCircleUpDownDuotone,
    PiListNumbersDuotone,
    PiCoinDuotone,
    PiCalendarDuotone,
    PiShapesDuotone,
    PiNewspaperClippingDuotone,
};

export type ReactMenuIconType = keyof typeof reactMenuIcons;
