import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useFilters from '../../hooks/useFilters';
import useFetch from '../../hooks/useFetch';
import { Text } from 'rizzui';
import { UserService } from '../../services/user/userService';
import { getIconByExtension } from '../../hooks/useGetIconByExtension';
import useHandleDownloadDocument from '../../hooks/useDownloadDocument';
import { Loader } from '../loader/SpinnerLogo';
import { DocumentsService } from '../../services/document/documentsService';

interface RecentDocumentsProps {
    updateList: boolean;
    setUpdateList: (value: boolean) => void;
 }

const RecentDocuments: React.FC<RecentDocumentsProps> = ({ updateList, setUpdateList }) => {

    const { id = '' } = useParams<{ id: string }>();
    const { filters } = useFilters({ userId: id }, [{ field: 'created_at', order: 'desc' }], 1, 15);
    const { handleDownloadDocumentNewTab } = useHandleDownloadDocument();
    const userService = new UserService();
    const documentService = new DocumentsService();

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await userService.getUserDocuments(id);
        return response.getResponseData();
    }, [filters]));


    useEffect(() => {
        if (updateList) {
            refetch();
            setUpdateList(false);
        }
    }, [updateList]);

    if (loading) return <Loader />;

    return (
        <>
            <Text className='pt-7 pb-4 text-lg font-semibold'>Recientes</Text>

            {data?.map((document: any) => (
                <div
                    key={document.id}
                    className="p-2 flex items-center justify-between mb-1 cursor-pointer rounded hover:bg-sky-100"
                    onClick={() => handleDownloadDocumentNewTab(documentService.downloadDocument, document.id, document.originalName, document.extension)}
                >
                    <div className="flex items-center">
                        <span className="text-2xl mr-3">
                            {getIconByExtension(document.extension)}
                        </span>
                        <div>
                            <p className="text-sm text-gray-800">{document.originalName}</p>
                            <p className="text-sm text-gray-500">Directorio: {document.subdirectory}</p>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default RecentDocuments;