import { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Input, InputProps } from 'rizzui';

const overwriteLabel = (WrappedComponent: ForwardRefExoticComponent<InputProps & RefAttributes<HTMLInputElement>>) => {
    return ({ label, ...props }: any) => (
        <WrappedComponent
            label={<span>{label} <span className="text-red-500">*</span></span>}
            {...props}
        />
    );
};

const RequiredInput = overwriteLabel(Input);

export default RequiredInput;