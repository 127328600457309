import React from 'react';
import ProfileLayout from '../../ProfileLayout';
import { FiltersProvider } from '../../../../../components/providers/FiltersProvider';
import UserChangelogs from './UserChangelogs';

interface UserChangelogsWrapperProps { }

const UserChangelogsWrapper: React.FC<UserChangelogsWrapperProps> = () => {

    return (
        <FiltersProvider>
            <ProfileLayout>
                <UserChangelogs></UserChangelogs>
            </ProfileLayout>
        </FiltersProvider>
    );
};

export default UserChangelogsWrapper;