import { menuRoutes } from "./menu";
import Dashboard from "../pages/dashboard/Dashboard";
import UsersWrapper from "../pages/users";
import UsersListWrapper from "../pages/users/list/UsersListWrapper";
import OrganisationWrapper from "../pages/organisations";
import OrganisationsListWrapper from "../pages/organisations/list/OrganisationsListWrapper";
import OrganisationCreate from "../pages/organisations/create/OrganisationCreate";
import OrganisationEdit from "../pages/organisations/edit/OrganisationEdit";
import UserEdit from "../pages/users/edit/UserEdit";
import UserCreate from "../pages/users/create/UserCreate";
import UserInfo from "../pages/users/profile/views/UserInfo";
import RolesWrapper from "../pages/roles";
import RolesListWrapper from "../pages/roles/list/RolesListWrapper";
import RoleCreate from "../pages/roles/create/RoleCreate";
import RoleEdit from "../pages/roles/edit/RoleEdit";
import UserPermissions from "../pages/users/profile/views/UserPermissions";
import UserDocuments from "../pages/users/profile/views/documents/UserDocuments";
import UserChangelogsWrapper from "../pages/users/profile/views/changelogs/UserChangelogsWrapper";
import UserEditPermissions from "../pages/users/edit/UserEditPermissions";
import UserContracts from "../pages/users/profile/views/UserContracts";
import UserStats from "../pages/users/profile/views/UserStats";
import CorporationsWrapper from "../pages/corporations";
import CorporationsListWrapper from "../pages/corporations/list/CorporationsListWrapper";
import StatesWrapper from "../pages/states";
import StatesListWrapper from "../pages/states/list/StatesListWrapper";

const protectedRoutes = [
	/******************************************************
	 * Dashboard
	 ******************************************************/
	{
		path: menuRoutes.dashboard.path,
		element: <Dashboard />,
	},
	/******************************************************
	 * Organisations
	 ******************************************************/
	{
		path: menuRoutes.organisations.path,
		element: <OrganisationWrapper />,
		sub: [
			{
				element: <OrganisationsListWrapper />,
				access: {
					group: "companies",
					action: "list_company",
				},
				index: true,
			},
			{
				element: <OrganisationCreate />,
				path: menuRoutes.organisations.create,
				access: {
					group: "companies",
					action: "create_company"
				}
			},
			{
				element: <OrganisationEdit />,
				path: ':id/edit',
				access: {
					group: "companies",
					action: "edit_company"
				}
			}
		],
	},

	/******************************************************
	 * Roles
	 ******************************************************/
	{
		path: menuRoutes.roles.path,
		element: <RolesWrapper />,
		sub: [
			{
				element: <RolesListWrapper />,
				access: {
					group: 'roles',
					action: 'list_roles'
				},
				index: true,
			},
			{
				element: <RoleCreate />,
				path: menuRoutes.roles.create,
				access: {
					group: 'roles',
					action: 'create_roles'
				}
			},
			{
				element: <RoleEdit />,
				path: menuRoutes.roles.edit,
				access: {
					group: 'roles',
					action: 'edit_roles'
				}
			},
		]
	},

	/******************************************************
	 * Users
	 ******************************************************/
	{
		path: menuRoutes.users.path,
		element: <UsersWrapper />,
		sub: [
			{
				element: <UsersListWrapper />,
				access: {
					group: 'user',
					action: 'list_user'
				},
				index: true,
			},
			{
				element: <UserInfo />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.info,
				access: {
					group: 'user',
					action: 'get_user'
				},
			},
			{
				element: <UserStats />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.stats,
				access: {
					group: 'user',
					action: 'user_profile_stats'
				},
			},
			{
				element: <UserDocuments />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.documents,
				access: {
					group: 'user',
					action: 'user_profile_documents'
				},
			},
			{
				element: <UserPermissions />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.permissions,
				access: {
					group: 'user',
					action: 'user_profile_permissions'
				},
			},
			{
				element: <UserContracts />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.contract_history,
				access: {
					group: 'user',
					action: 'user_profile_contract_history'
				},
			},
			{
				element: <UserChangelogsWrapper />,
				path: menuRoutes.users.profile.path + menuRoutes.users.profile.activity,
				access: {
					group: 'user',
					action: 'user_profile_activity'
				},
			},
			{
				element: <UserCreate />,
				path: menuRoutes.users.create,
				access: {
					group: 'user',
					action: 'create_user'
				}
			},
			{
				element: <UserEdit />,
				path: menuRoutes.users.edit,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
			{
				element: <UserEditPermissions />,
				path: menuRoutes.users.edit_permissions,
				access: {
					group: 'user',
					action: 'edit_user'
				}
			},
		]
	},

	/******************************************************
	 * States
	 ******************************************************/
	{
		path: menuRoutes.states.path,
		element: <StatesWrapper />,
		sub: [
			{
				element: <StatesListWrapper />,
				access: {
					group: 'statuses',
					action: 'list_statuses'
				},
				index: true,
			},
		]
	},

	/******************************************************
	 * Corporations
	 ******************************************************/
	{
		path: menuRoutes.corporations.path,
		element: <CorporationsWrapper />,
		sub: [
			{
				element: <CorporationsListWrapper />,
				access: {
					group: 'corporation',
					action: 'list_corporation'
				},
				index: true,
			},
		]
	},
];

const contents = [...protectedRoutes];
export default contents;