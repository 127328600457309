import { FC, Fragment } from "react"
import { Navigate, Outlet } from "react-router-dom"
import PageWrapper from "../../layout/PageWrapper/PageWrapper"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import Header from "./_headers/DefaultHeader"

interface AppLayoutProps {
    children?: React.ReactNode
}

const AppLayout: FC<AppLayoutProps> = ({ children }) => {

    const { isAuthenticated } = useSelector((state: RootState) => state.auth);

    if (isAuthenticated) {
        return (
            <Fragment>
                <Header></Header>
                <div className="wrapper">
                    <main className="content">
                        <PageWrapper>
                            <Outlet />
                        </PageWrapper>
                    </main>
                </div>
            </Fragment>
        )
    } else {
        return (
            <Navigate to="/login" />
        )
    }
}

export default AppLayout