import React, { Fragment, useState } from 'react';
import * as yup from 'yup';
import { User } from '../../type/entities/user-type';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import FormGroup from '../../layout/shared/form-group';
import { FileInput, Input, Password, Switch } from 'rizzui';
import Page from '../../layout/Page/Page';
import FormFooter from '../_layout/_footers/form-footer';
import { menuRoutes } from '../../router/menu';
import Spinner from '../../components/bootstrap/Spinner';
import { PiEnvelopeSimple } from 'react-icons/pi';
import useRolesByUser from '../../hooks/api-calls/useRolesByUser';
import { UserService } from '../../services/user/userService';
import { toast } from 'sonner';
import classNames from 'classnames';
import PlaceholderImage from '../../components/extras/PlaceholderImage';
import AsyncImg from '../../components/extras/AsyncImg';
import DeletePopover from '../../components/buttons/DeletePopover';
import CustomSelect from '../../components/forms/CustomSelect';
import RequiredInput from '../../components/forms/InputHOC';

interface CreateFormProps {
  isLoading: boolean;
  submit: Function;
  data?: User | undefined;
  profileImg?: any;
}

const schema = yup.object({
  name: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
  lastName: yup.string().min(1, 'Demasiado corto').max(50, 'Demasiado largo').required('Campo obligatorio'),
  email: yup.string().email('Email no válido').required('Campo obligatorio'),
  password: yup.string().min(8, 'Contraseña de al menos 8 caracteres').max(30, 'Contraseña menor de 30 caracteres').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'La contraseña debe contener al menos una letra mayúscula, una minúscula y un número').nullable(),
  passwordConfirm: yup.string().when('password', {
    is: (val: string | null | undefined) => val !== null && val !== undefined && val.length > 0,
    then: (schema: yup.Schema) => schema.oneOf([yup.ref('password'), ''], 'Las contraseñas no coinciden'),
    otherwise: (schema: yup.Schema) => schema.nullable(),
  }).nullable(),
  commercialNetwork: yup.string(),
  department: yup.string(),
  accessThrowCommercialNetworkIp: yup.boolean(),
  profileImg: yup.mixed(),
  roleId: yup.string().required('Campo obligatorio'),
});

const UserForm: React.FC<CreateFormProps> = ({ isLoading, submit, data, profileImg }) => {

  const { id = '' } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getRolesList } = useRolesByUser();
  const mode = data ? 'Editar' : 'Crear';
  const service = new UserService();

  const [selectedImage, setSelectedImage] = useState<any>(null);

  const handleImageUpload = async (event: React.ChangeEvent<any>) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setSelectedImage(reader.result);
    };
    reader.readAsDataURL(file);

    try {
      const response = await service.editUserImg(id, file);

      const responseData = response.getResponseData();

      if (responseData.success) {
        setTimeout(() => {
          toast.success('Imagen de perfil actualizada');
        }, 100);
      } else {
        toast.error(responseData.message);
        setSelectedImage(null);
      }
    } catch (error: any) {
      toast.error("Formato de imagen incorrecto");
      setSelectedImage(null);
    }
  };

  const deleteImage = async () => {
    try {
      const response = await service.deleteUserImg(id);

      const responseData = response.getResponseData();

      if (responseData.success) {
        setSelectedImage(null);
        window.location.reload();
      }
    } catch (error: any) {
      toast.error("Error al eliminar la imagen");
    }
  };

  const formik = useFormik({
    initialValues: {
      userId: data?.id ?? '',
      company: data?.userRoles[0]?.company?.id ?? '',
      roleId: data?.userRoles[0].role.id ?? '',
      name: data?.name ?? '',
      lastName: data?.lastName ?? '',
      email: data?.email ?? '',
      password: '',
      passwordConfirm: '',
      commercialNetwork: data?.commercialNetwork ?? '',
      department: data?.department ?? '',
      accessThrowCommercialNetworkIp: data?.accessThrowCommercialNetworkIp ?? false,
      profileImg: data?.profileImg?.id ?? undefined,
    },
    validationSchema: schema,
    onSubmit: values => {submit(values);},
  });

  const getContent = () => {
    return (
      <>
        <div className="@container">
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-10 grid gap-7 divide-y-2 divide-dashed divide-gray-200 @2xl:gap-9 @3xl:gap-11 font-medium">
              <FormGroup
                title="Imagen de perfil"
                description="Se mostrará como imagen de perfil del usuario"
                className={classNames({ 'hidden': mode === 'Crear', 'mt-4': true })}
              >
                <div className='flex justify-start items-center'>
                  <FileInput onChange={(e: React.ChangeEvent<any>) => handleImageUpload(e)} autoComplete='photo' placeholder={"Cambiar imagen"} className='me-3' />
                  <DeletePopover
                    title={`Eliminar imagen`}
                    description={`¿Estás seguro de que deseas eliminar la imagen?`}
                    onDelete={deleteImage}
                    size={32}
                    className={classNames({ 'hidden': !profileImg?.id })}
                  />
                </div>
                {selectedImage
                  ? <img src={selectedImage} alt="selected" className='mx-auto d-block img-fluid rounded' />
                  : profileImg
                    ? <AsyncImg id={profileImg.id} isBackground className="mx-auto d-block img-fluid rounded" />
                    : <PlaceholderImage width={200} height={200} className='mx-auto d-block img-fluid rounded' />
                }
              </FormGroup>

              <FormGroup
                title="Información General"
                description="Datos principales del usuario"
                className='pt-6'
              >
                <RequiredInput
                  id='name'
                  type="text"
                  label="Nombre"
                  className="[&>label>span]:font-medium"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={formik.errors.name}
                />

                <RequiredInput
                  id='lastName'
                  type="text"
                  label="Apellidos"
                  className="[&>label>span]:font-medium"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  error={formik.errors.lastName}
                />

                <RequiredInput
                  id='email'
                  prefix={<PiEnvelopeSimple className="h-6 w-6 text-gray-500" />}
                  type="email"
                  label="Correo Electrónico"
                  className="[&>label>span]:font-medium"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={formik.errors.email}
                />

                <CustomSelect
                  isSearchable
                  id={'roleId'}
                  label="Rol"
                  value={{ value: formik.values.roleId, label: getRolesList()?.find((g: any) => g.value === formik.values.roleId)?.label }}
                  options={getRolesList()}
                  onChange={(e: any) => { formik.setFieldValue('roleId', e?.value) }}
                  required
                  error={formik.errors.roleId}
                />

                <Password
                  id='password'
                  autoComplete="new-password"
                  label="Contraseña"
                  className="[&>label>span]:font-medium"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  error={formik.errors.password}
                />

                <Password
                  id='passwordConfirm'
                  autoComplete='repeat-new-password'
                  label="Confirmar contraseña"
                  className="[&>label>span]:font-medium"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.passwordConfirm}
                  error={formik.errors.passwordConfirm}
                />
              </FormGroup>

              <FormGroup
                title="Información Adicional"
                description="Datos adicionales del usuario"
                className='pt-6 hidden'
              >
                <Input
                  id='commercialNetwork'
                  type="text"
                  label="Red Comercial"
                  className="[&>label>span]:font-medium"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.commercialNetwork}
                  error={formik.errors.commercialNetwork}
                />

                <Switch
                  id='accessThrowCommercialNetworkIp'
                  label="Acceso a través de la IP de la red comercial"
                  switchKnobClassName='bg-secondary'
                  className="[&>label>span]:font-medium"
                  onChange={formik.handleChange}
                  checked={formik.values.accessThrowCommercialNetworkIp}
                />

                <Input
                  id='department'
                  type="text"
                  label="Departamento"
                  className="[&>label>span]:font-medium flex align-middle"
                  inputClassName="text-sm"
                  onChange={formik.handleChange}
                  value={formik.values.department}
                  error={formik.errors.department}
                />
              </FormGroup>
            </div>
          </form>
        </div>
      </>
    )
  }

  return (
    <Fragment>
      <Page container="fluid">
        {(mode === "Editar" && !data) && <Spinner />}
        {(mode === "Editar" && data) && getContent()}
        {mode === "Crear" && getContent()}
      </Page>
      <FormFooter
        submitBtnText={mode + ' Usuario'}
        handleCancelBtn={() => navigate(menuRoutes.users.path)}
        handleSubmitBtn={formik.submitForm} isLoading={isLoading}
      />
    </Fragment>
  )
};

export default UserForm;