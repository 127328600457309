import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { menuRoutes } from "../../../router/menu";
import { UserService } from "../../../services/user/userService";
import UserForm from "../UserForm";
import PageHeader from "../../../layout/shared/page-header";
import AttivaCard from "../../../components/card/AttivaCard";

const pageHeader = {
    title: "Crear Usuario",
    breadcrumb: [
        {
            href: menuRoutes.users.path,
            name: "Usuarios",
        },
        {
            name: "Crear",
        },
    ],
};

const UserCreate: FC = () => {

    const navigate = useNavigate();
    const service = new UserService();

    const [loading, setLoading] = useState<boolean>(false);

    const handleSubmit = async (values: any) => {
        setLoading(true);
        const formData = new FormData();

        for (let value in values) {
            if (values[value]) {
                formData.append(value, values[value]);
            }
        }

        try {
            const response = await service.createUser(formData);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Usuario creado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <PageHeader title={pageHeader.title} breadcrumb={pageHeader.breadcrumb} />

            <AttivaCard title="Datos Principales" className="mb-4">
                <UserForm submit={handleSubmit} isLoading={loading} />
            </AttivaCard>
        </>
    );
};

export default UserCreate;