import { FC, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useFetch from "../../../hooks/useFetch";
import { Loader } from "../../../components/loader/SpinnerLogo";
import { toast } from "sonner";
import { menuRoutes } from "../../../router/menu";
import { UserService } from "../../../services/user/userService";
import { User } from "../../../type/entities/user-type";
import UserForm from "../UserForm";
import UserEditLayout from "./UserEditLayout";

const pageHeader = {
    title: "Editar Usuario",
    breadcrumb: [
        {
            href: menuRoutes.users.path,
            name: "Usuarios",
        },
        {
            name: "Editar",
        },
    ],
};



const UserEdit: FC = () => {

    const { id = "" } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const service = new UserService();

    const [data, loading, error] = useFetch(useCallback(async () => {
        const response = await service.getUserById(id as string);
        return response.getResponseData() as User;
    }, [id]));

    const handleSubmit = async (values: any) => {
        const formData = new FormData();

        for (let value in values) {
            if (values[value]) {
                formData.append(value, values[value]);
            }
        }

        try {
            if (values.password !== "" && values.passwordConfirm !== "") {
                formData.delete("password");
                formData.delete("passwordConfirm");
                const result = await service.changePassword(id, values.password, values.passwordConfirm);

                if (result.getResponseData().success) {
                    toast.success("Contraseña cambiada correctamente");
                } else {
                    toast.error("Error al cambiar la contraseña");
                }
            }
            const response = await service.editUser(formData);
            const responseData = response.getResponseData();

            if (responseData.success) {
                navigate(-1);
                toast.success("Usuario editado correctamente");
            } else {
                responseData.data.errors.forEach((error: any) => {
                    toast.error(error.message);
                });
            }
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    if (loading) return <Loader />;

    return (
        <UserEditLayout>
            {(data !== null && data !== undefined) && <UserForm data={data} submit={handleSubmit} isLoading={loading} profileImg={data.profileImg} />}
        </UserEditLayout>
    );
};

export default UserEdit;