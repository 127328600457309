export default function DocumentIcon({ ...props }: React.SVGProps<SVGSVGElement>) {

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 36 36"
        >
            <g fillRule="evenodd">
                <path
                    fill="#00acd8"
                    d="M29.43 2h-16v8c0 .63-.51 1.14-1.14 1.14h-8v20.57A2.285 2.285 0 006.58 34h22.85a2.285 2.285 0 002.29-2.29V4.29A2.29 2.29 0 0029.43 2zM16.86 28.28H10A1.14 1.14 0 1110 26h6.86a1.14 1.14 0 110 2.28zM26 22.57H10a1.14 1.14 0 110-2.28h16a1.14 1.14 0 110 2.28zm0-5.71H10a1.14 1.14 0 110-2.28h16a1.14 1.14 0 110 2.28z"
                    data-original="#43d685"
                ></path>
                <path
                    fill="#111"
                    d="M11.15 2c-.67-.04-1.34.19-1.81.67L4.97 7.04c-.48.48-.71 1.14-.67 1.81h6.86V2z"
                    data-original="#072a30"
                ></path>
            </g>
        </svg>
    );
}