import React, { useEffect, useState } from 'react';
import { PiTrashDuotone } from 'react-icons/pi';
import { Button, Input } from 'rizzui';
import { FilterOptions } from '../../../hooks/useFilters';
import CustomSelect from '../../../components/forms/CustomSelect';
import useRoles from '../../../hooks/api-calls/useRoles';
import { usePrivilege } from '../../../components/priviledge/PriviledgeProvider';

interface CorporationsFiltersProps {
    updateFilters: (filters: any) => void
    resetFilters: (limit: any) => void
    filters: FilterOptions
}

const CorporationsFilters: React.FC<CorporationsFiltersProps> = ({ filters, updateFilters, resetFilters }) => {

    const [filter_filters, setFilterFilters] = useState<any>({});
    const [loaded, setLoaded] = useState<boolean>(false);
    const { userCan } = usePrivilege();
    const { getRolesList } = useRoles();

    useEffect(() => {
        if (loaded && filter_filters !== filters.filter_filters) {
            const delaySearch = setTimeout(() => {
                updateFilters({ ...filter_filters });
            }, 1000);
            return () => clearTimeout(delaySearch);
        }
    }, [filter_filters, loaded]);

    useEffect(() => {
        setFilterFilters(filters.filter_filters || '');
    }, [filters]);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <>
            <Input
                id='search_text'
                type="text"
                label="Buscar"
                className="[&>label>span]:font-medium"
                inputClassName="text-sm"
                onChange={(e) => setFilterFilters({ ...filter_filters, search_text: e.target.value })}
                value={filter_filters?.search_text ?? ''}
            />

            <CustomSelect
                isSearchable
                id={'active'}
                label="Activo"
                options={[{ label: 'Todos', value: 'null' }, { label: 'Activo', value: true }, { label: 'Inactivo', value: false }]}
                onChange={(e: any) => {
                    setFilterFilters({ ...filter_filters, active: e.value }) 
                }}
                defaultValue={filter_filters?.active ?? 'null'}
            />

            <CustomSelect
                isSearchable
                isMulti
                id={'roleId'}
                label="Rol"
                options={getRolesList()}
                onChange={(e: any) => { setFilterFilters({ ...filter_filters, roles: e.map((rol: any) => rol.value) }) }}
                value={
                    filter_filters?.roles
                        ? getRolesList().filter((rol: any) => filter_filters.roles.includes(rol.value))
                        : []
                }
                display={userCan('list_roles', 'roles')}
            />


            <Button
                size="sm"
                onClick={() => {
                    resetFilters(50);
                }}
                variant="flat"
                className="h-9 bg-gray-200/70"
            >
                <PiTrashDuotone className="me-1.5 h-[17px] w-[17px]" /> Limpiar
            </Button>
        </>
    );
};

export default CorporationsFilters;