import React, { useCallback, useState } from 'react';
import Page from '../../../layout/Page/Page';
import { useFiltersPR } from '../../../components/providers/FiltersProvider';
import useFetch from '../../../hooks/useFetch';
import { ActionIcon, Switch, Tooltip } from 'rizzui';
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineHistoryEdu, MdOutlineLockReset, MdOutlineModeEditOutline } from "react-icons/md";
import { HiOutlineEye } from "react-icons/hi2";
import DeletePopover from '../../../components/buttons/DeletePopover';
import moment from 'moment';
import { menuRoutes } from '../../../router/menu';
import { toast } from 'sonner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { FilterDrawerView } from '../../../components/table/components/TableFilter';
import CustomPopover from '../../../components/buttons/Popover';
import CorporationsFilters from './CorporationsFilters';
import { CorporationService } from '../../../services/corporation/corporationService';
import { CorporationsApiResponse } from '../../../type/entities/corporation-type';

interface CorporationsListProps {
}

const CorporationsList: React.FC<CorporationsListProps> = () => {

    const { user } = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate();
    const { filters, updateFilters, updateFilterOrder, updatePage, updatePageSize, resetFilters } = useFiltersPR();
    const service = new CorporationService();

    const [changingStatus, setChangingStatus] = useState<string[]>([]);
    const [openFilters, setOpenFilters] = useState<boolean>(false);

    const [data, loading, error, refetch] = useFetch(useCallback(async () => {
        const response = await service.listCorporations(filters);
        return response.getResponseData() as CorporationsApiResponse;
    }, [filters]));

    const toggleStatus = async (id: string, status: boolean) => {
        try {
            setChangingStatus([...changingStatus, id]);
            const response = (await service.toggleCorporationStatus(id, status)).getResponseData();
            if (response.success) {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                refetch();
                toast.success(status ? "Se ha desactivado la empresa" : "Se ha activado la empresa");
            } else {
                setChangingStatus(changingStatus.filter((item) => item !== id));
                toast.error(response.message);
            }
        } catch (error: any) {
            setChangingStatus(changingStatus.filter((item) => item !== id));
            toast.error(error.message);
        }
    };

    const handleDelete = async (id: string) => {
        await service.deleteCorporation(id);
        refetch();
    };

    const handleMultiDelete = async (ids: string[]) => {
        await service.deleteMultiCorporations(ids);
        refetch();
    };

    return (
        <Page container='fluid'>
            {data !== undefined && (
                <></>
            )}

            <FilterDrawerView isOpen={openFilters} setOpenDrawer={setOpenFilters} drawerTitle={'Filtros Usuarios'}>
                <div className="grid grid-cols-1 gap-6">
                    <CorporationsFilters filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} />
                </div>
            </FilterDrawerView>
        </Page>
    );
};

export default CorporationsList;