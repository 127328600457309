export const translations: any = {
    id: 'ID',
    name: 'Nombre',
    originalName: 'Nombre',
    lastName: 'Apellidos',
    user: 'Usuario',
    email: 'Email',
    address: 'Dirección',
    cif: 'CIF',
    commercialNetwork: 'Red comercial',
    accessThrowCommercialNetworkIp: 'Acceso por IP de red comercial',
    userRoles: 'Rol',
    department: 'Departamento',
    logo: 'Logo',
    profileImg: 'Imagen de perfil',
    active: 'Activo',
    createdAt: 'Fecha de creación',
    updatedAt: 'Fecha de actualización',
    lastLogin: 'Último acceso',
    entityType: 'Entidad',
    documentType: 'Tipo de documento',
    subdirectory: 'Directorio',
    status: 'Estado',
    company: 'Organización',
    description: 'Descripción',
};

export const imageFields = [
    'logo',
    'profileImg',
];

export const translateColumnName = (key: any) => {
    // Convierte camelCase a "Camel Case"
    const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();

    // Retorna la traducción si existe, o el nombre formateado
    return translations[key] || formattedKey.charAt(0).toUpperCase() + formattedKey.slice(1);
};